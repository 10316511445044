import React, {useState } from 'react';
// import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import styled from "styled-components";
import { useDispatch, useSelector} from "react-redux";
import { getLayout, getConfig } from "./../../../store/settings/selectors";
import { getTranslations, getLocale } from "./../../../store/localization/selectors";
import {getPermissions, getUser} from "./../../../store/auth/selectors";
import {showPlansModal, toggleFilters} from "./../../../store/settings/actions";

import {LAYOUT_XS, LAYOUT_SM, LAYOUT_MD} from './../../../settings';
//
import NewsService from './../../../services/NewsService';
import RecommendedService from './../../../services/RecommendedService';

import Logo from './../../../components/App/Logo';
import Container from './../../../components/App/Container';
import Spacer from './../../../components/App/Spacer';
// import Post from './../../../components/App/Post';
import Image from './../../../components/App/Image';
import CompactMenuIcon from './../../../components/App/CompactMenuIcon';
import Slider from './../../../components/App/Slider';
import Button from "./../../../components/App/Button";
import HomeProduct from './HomeProduct';
import HomeProject from './HomeProject';
import HomeAdv from './HomeAdv';

// import FindUs from './../../../components/Common/FindUs';
// import Sidebar from './Sidebar';
import DataService from "../../../services/DataService";
import { isArray } from 'lodash';
// import {zipWith} from "rxjs/operators";
import {PERMISSION_PRODUCT} from "../../../store/auth/permissions";
import {login} from "../../../store/auth/actions";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;

  height: calc(100vh - 50px);
  height: calc(calc(var(--vh, 1vh) * 100) - 50px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  margin-top: 3px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* min-height: 100%; */
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
`;
const MacroRow = styled.div`
  display: flex;
  flex-direction: ${props => props.layout === LAYOUT_XS || props.layout === LAYOUT_SM ? "column" : "row"};
  flex-wrap: wrap;
  margin-right: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  margin-left: -${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
`;
const MacroCol = styled.div`
  padding: 0px ${props => props.size && (props.layout === LAYOUT_MD) ? 7 : 12}px;
  width: ${props => props.size && (props.layout !== LAYOUT_XS && props.layout !== LAYOUT_SM) ? props.size : 100}%;

  ${props => props.autoHeight ? `

    & > * {
      height: 100%;
      display: flex;
    }
  ` : `

  `};
`;

// const Title = styled.div`
//   text-align: center;
//   font-size: 14px;
//   font-weight: 600;
//   color: #E4018E;
// `;

const Notification = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  color: #A3A3A3;
  margin-top:20px;
`;




const Hero = styled.div`
  color: #000000;
  border-radius: 12px;
  padding: 20px;
`;
const HeroImage = styled.div`
  border-radius: 12px;
  overflow: hidden;

  & img{
    margin-bottom: -4px;
  }
`;
const HeroTitle = styled.div`
  font-weight: 700;
/*  margin-top: 10px;*/
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 32px;
`;
const HeroText = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

const Block = styled.div`
  border-radius: 12px;
  padding: 20px;
`;
const BlockTitle = styled.div`
  color: #E5087E;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  margin-top: 10px;
  padding-left: 10px;
  height: 18px;
`;
const HeroCta = styled.div`
  text-align: left;
`;

const AdvBlock = styled.div`
  margin: 0px 0px;
  padding: 0px 5px 5px;
  border: 2px solid #e4018e;
  border-radius: 5px;
`;

const Adv = styled.div`
  color: #818181;
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 12px;
  margin-top: 8px;
  height: 18px;
`;

export const CompactMenuIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 15px;
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
   z-index: 9999;
`;

export default function Home(){

  const dispatch = useDispatch();
  dispatch(toggleFilters(false));

  const layout = useSelector(getLayout);
  const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  const config = useSelector(getConfig);
  const user = useSelector(getUser);
  const permissions = useSelector(getPermissions);

  const [heroes, setHeroes] = useState(null);
  // const [save, setSave] = useState(null);
  // const [banners, setBanners] = useState(null);
  const [adv, setADV] = useState(null);

  // const [list, setList] = useState(null);
  // const [listDx, setListDx] = useState(null);
  const [recommended, setRecommended] = useState(null);
  const [products, setProducts] = useState(null);
  const [projects, setProjects] = useState(null);

  function shuffleArray(arr) {
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  }

  async function loadData() {

    try {
      await setProducts({});
      await setProjects({});
      const ret = await RecommendedService.getList(user ? user.id : null);
      
      // Lista prodotti
      let productIds = ret.reduce((acc, area) => area.code !== 'casehistories' ? [...acc, ...area.items.map(({item}) => item)] : acc, []);
      productIds = productIds.concat(ret.reduce((acc, area) => area.code !== 'casehistories' ? [...acc, area.adv] : acc, []));
      let productsArray = await DataService.getList({area: 'products', ids: productIds, limit: productIds.length});
      const productsObj = isArray(productsArray) ? productsArray.reduce((acc, item) => ({...acc, [item.id]: item}), {}) : productsArray;
      await setProducts(productsObj);

      // Lista progetti
      let projectIds = ret.reduce((acc, area) => area.code === 'casehistories' ? [...acc, ...area.items.map(({item}) => item)] : acc, []);
      projectIds = projectIds.concat(ret.reduce((acc, area) => area.code === 'casehistories' ? [...acc, area.adv] : acc, []));
      let projectsArray = await DataService.getList({area: 'casehistories', ids: projectIds, limit: projectIds.length});
      const projectsObj = isArray(projectsArray) ? projectsArray.reduce((acc, item) => ({...acc, [item.id]: item}), {}) : projectsArray;
      await setProjects(projectsObj);
      await setRecommended(ret);

      // Contenuti
      const data = await NewsService.getList();
      await setHeroes(data['Banner CX']);
      // await setSave(data['Banner DX'][0]);
      // await setBanners(data['Fascia DX']);
      Object.keys(data['ADV']).map(key => {
        data['ADV'][key] = shuffleArray(data['ADV'][key]);
        return 0;
      });      
      await setADV(data['ADV']);

    } catch (e) {}

  }

  if(!recommended && products === null){
    loadData();
  }

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  function productClickHandler(e) {
    if (!config.enable_login || permissions.indexOf(PERMISSION_PRODUCT) === -1) {
      e.preventDefault();
      dispatch(showPlansModal())
    }
  }

  const blockLang = lang === "it" ? "it" : "en";

  return (
    <Wrapper>
      { layout !== LAYOUT_XS && layout !== LAYOUT_SM ?
        <Spacer size={{value: 34, unit: "px"}} />
      : 
        <>
          {/*<Sidebar selected={"homepage"} />*/}
          <Spacer size={{value: 34, unit: "px"}} />
          <CompactMenuIconWrapper>
            <CompactMenuIcon />
          </CompactMenuIconWrapper>
        </>
       } 
      <MainContainer>
        <Container>
          <center>
            { layout === LAYOUT_XS || layout === LAYOUT_SM ?
              <Spacer size={{value: 34, unit: "px"}} />
            : null }
             { layout !== LAYOUT_XS && layout !== LAYOUT_SM ?
              <Logo height={{value: 56, unit: "px"}} variant="plus" />
            : 
              <Logo width={{value: 70, unit: "vw"}} variant="plus" />
            }
            <Spacer size={{value: 34, unit: "px"}} />
          </center>
          

              {/*heroes ?
                <>
                  <Slider padding={{value: 0, unit: "px"}} size={1} showDots={false} showArrows={false} timing={10000} items={heroes.map(item =>
                    <Hero key={item.code}>
                      <MacroRow layout={layout}>
                        <MacroCol size="50" layout={layout}>
                          <HeroTitle>{item[`titolo_${blockLang}`]}</HeroTitle>
                          <HeroText>
                            {ReactHtmlParser(item[`abstract_${blockLang}`])}
                            {item[`cta_${blockLang}`] && item[`link_${blockLang}`] ?
                              <HeroCta>
                                <br/>
                                <a href={item[`link_${blockLang}`]} target="_blank">
                                  <Button size={"default"} variant={"black-o"} display={"inline-block"}>{item[`cta_${blockLang}`]}</Button>
                                </a>
                              </HeroCta>
                            : null}
                            {(!user && !item[`cta_${blockLang}`] && !item[`link_${blockLang}`]) ?
                              <HeroCta>
                                <br/>
                                <Button onClick={() => dispatch(login())} size={"default"} variant={"pink-o"} display={"inline-block"}>{translations["UI"]["Login"]}</Button>
                              </HeroCta>
                            : null}
                          </HeroText>
                        </MacroCol>
                        <MacroCol size="50" layout={layout}>
                          <HeroImage>
                            <Image
                              src={item.immagine}
                            />
                          </HeroImage>
                        </MacroCol>
                      </MacroRow>
                    </Hero>
                  )} />

                  <Spacer size={{value: 34, unit: "px"}} />
                </>
              : null*/}
              {heroes ?
                <>
                  <Hero key={heroes[0].code}>
                    <MacroRow layout={layout}>
                      <MacroCol size={heroes[1] ? 50 : 100} layout={layout}>
                        {heroes[0] ? 
                          <>
                            {heroes[0].immagine ? 
                              <HeroImage>
                                <Image
                                  src={heroes[0].immagine}
                                />
                              </HeroImage>
                            : 
                              <>
                                <HeroTitle>{heroes[0][`titolo_${blockLang}`]}</HeroTitle>
                                <HeroText>
                                  {ReactHtmlParser(heroes[0][`abstract_${blockLang}`])}
                                  {heroes[0][`cta_${blockLang}`] && heroes[0][`link_${blockLang}`] ?
                                    <HeroCta>
                                      <br/>
                                      <a rel="noopener noreferrer" href={heroes[0][`link_${blockLang}`]} target="_blank">
                                        <Button size={"default"} variant={"black-o"} display={"inline-block"}>{heroes[0][`cta_${blockLang}`]}</Button>
                                      </a>
                                    </HeroCta>
                                  : null}
                                  {(!user && !heroes[0][`cta_${blockLang}`] && !heroes[0][`link_${blockLang}`]) ?
                                    <HeroCta>
                                      <br/>
                                      <Button onClick={() => dispatch(login())} size={"default"} variant={"pink-o"} display={"inline-block"}>{translations["UI"]["Login"]}</Button>
                                    </HeroCta>
                                  : null}
                                </HeroText>
                              </>
                            }      
                          </>                  
                        : null }
                      </MacroCol>
                      {heroes[1] ? 
                        <MacroCol size="50" layout={layout}>
                          <>
                            {heroes[1].immagine ? 
                              <HeroImage>
                                <Image
                                  src={heroes[1].immagine}
                                />
                              </HeroImage>
                            : 
                              <>
                                <HeroTitle>{heroes[1][`titolo_${blockLang}`]}</HeroTitle>
                                <HeroText>
                                  {ReactHtmlParser(heroes[1][`abstract_${blockLang}`])}
                                  {heroes[1][`cta_${blockLang}`] && heroes[1][`link_${blockLang}`] ?
                                    <HeroCta>
                                      <br/>
                                      <a rel="noopener noreferrer" href={heroes[1][`link_${blockLang}`]} target="_blank">
                                        <Button size={"default"} variant={"black-o"} display={"inline-block"}>{heroes[1][`cta_${blockLang}`]}</Button>
                                      </a>
                                    </HeroCta>
                                  : null}
                                  {(!user && !heroes[1][`cta_${blockLang}`] && !heroes[1][`link_${blockLang}`]) ?
                                    <HeroCta>
                                      <br/>
                                      <Button onClick={() => dispatch(login())} size={"default"} variant={"pink-o"} display={"inline-block"}>{translations["UI"]["Login"]}</Button>
                                    </HeroCta>
                                  : null}
                                </HeroText>
                              </>
                            }       
                          </>        
                        </MacroCol>           
                      : null }
                    </MacroRow>
                  </Hero>
                  

                  <Spacer size={{value: 34, unit: "px"}} />
                </>
              : null }


              {recommended ?
                recommended.map((block, i) =>
                  <React.Fragment key={i}>
                    <Block>                      
                      <MacroRow layout={layout}>
                        {i % 2 === 0 ?
                          <MacroCol size="25" layout={layout}>
                            {adv && adv[block.code] && adv[block.code].length > 0 ? 
                              <AdvBlock>
                                <Adv>Advertising</Adv>
                                <Spacer size={{value: 5, unit: "px"}} />
                                <Slider 
                                  showDots={false} 
                                  showArrows={false} 
                                  timing={5000} 
                                  size={1} 
                                  padding={{
                                    value: 0, 
                                    unit: "px"
                                  }}
                                  items={adv[block.code].map(item => 
                                    <HomeAdv
                                      image={item["immagine"] ? item["immagine"] : null}
                                      abstract={item["abstract_"+lang] ? item["abstract_"+lang] : null}
                                      content={item["abstract_"+lang] ? item["abstract_"+lang] : null}
                                      ctaText={item["testo_"+lang] ? item["testo_"+lang] : null}
                                      ctaLink={item["link_"+lang] ? item["link_"+lang] : null}
                                    />
                                  )}
                                />
                                
                                {/* {block.code === "casehistories" ?
                                  (projects && projects.hasOwnProperty(block.adv) ? 
                                    <HomeProduct onClick={productClickHandler} id={block.adv} key={block.adv} item={projects[block.adv]} /> 
                                  : null)
                                :
                                  (products && products.hasOwnProperty(block.adv) ? 
                                    <HomeProduct onClick={productClickHandler} id={block.adv} key={block.adv} item={products[block.adv]} /> 
                                  : null)
                                } */}
                              </AdvBlock>
                            : null }
                          </MacroCol>
                        : null }
                        <MacroCol size="75" layout={layout}>
                          <BlockTitle>{translations["UI"]["_recommended-"+block.code]}</BlockTitle>
                          <Spacer size={{value: 14, unit: "px"}} />
                          <Slider showDots={false} size={layout === LAYOUT_XS || layout === LAYOUT_SM ? 1 : 3} items={block.items.map((item, index) =>
                            <>
                              {block.code === "casehistories" ?
                                (projects && projects.hasOwnProperty(item.item)) ? <HomeProject onClick={productClickHandler} id={item.item} key={item.item} item={projects[item.item]} /> : null
                              :
                                (products && products.hasOwnProperty(item.item)) ? <HomeProduct onClick={productClickHandler} id={item.item} key={item.item} item={products[item.item]} /> : null
                              }
                            </>
                          )} />
                        </MacroCol>
                        {i % 2 !== 0 ?
                          <MacroCol size="25" layout={layout}>
                            {adv && adv[block.code] && adv[block.code].length > 0 ? 
                              <AdvBlock>
                                <Adv>Advertising</Adv>
                                <Spacer size={{value: 5, unit: "px"}} />
                                <Slider 
                                  showDots={false} 
                                  showArrows={false} 
                                  timing={5000} 
                                  size={1} 
                                  padding={{
                                    value: 0, 
                                    unit: "px"
                                  }}
                                  items={adv[block.code].map(item => 
                                    <HomeAdv
                                      image={item["immagine"] ? item["immagine"] : null}
                                      abstract={item["abstract_"+lang] ? item["abstract_"+lang] : null}
                                      content={item["abstract_"+lang] ? item["abstract_"+lang] : null}
                                      ctaText={item["testo_"+lang] ? item["testo_"+lang] : null}
                                      ctaLink={item["link_"+lang] ? item["link_"+lang] : null}
                                    />
                                  )}
                                />
                                {/* {block.code === "casehistories" ?
                                  (projects && projects.hasOwnProperty(block.adv) ? 
                                    <HomeProduct onClick={productClickHandler} id={block.adv} key={block.adv} item={projects[block.adv]} /> 
                                  : null)
                                :
                                  (products && products.hasOwnProperty(block.adv) ? 
                                    <HomeProduct onClick={productClickHandler} id={block.adv} key={block.adv} item={products[block.adv]} /> 
                                  : null)
                                } */}
                              </AdvBlock>
                            : null }
                          </MacroCol>
                        : null }
                      </MacroRow>                        
                    </Block>
                    <Spacer size={{value: 34, unit: "px"}} />
                  </React.Fragment>
                )
              : null }


              {/*<Spacer size={{value: 95, unit: "px"}} />*/}
              {/*<FindUs />
              <Spacer size={{value: 34, unit: "px"}} />*/}
             {/* {save ?
                <>
                  <Post
                    title={save[`titolo_${blockLang}`]}
                    abstract={ReactHtmlParser(save[`abstract_${blockLang}`])}
                    content={save[`testo_${blockLang}`] ? ReactHtmlParser(save[`testo_${blockLang}`]) : null}
                    ctaText={save[`cta_${blockLang}`] ? ReactHtmlParser(save[`cta_${blockLang}`]) : null}
                    ctaLink={save[`link_${blockLang}`] ? ReactHtmlParser(save[`link_${blockLang}`]) : null}
                    image={save.immagine}
                  />
                  <Spacer size={{value: 34, unit: "px"}} />
                </>
              : null }
             */}


          <Notification>
            {translations["UI"]["_homepage-language-info"]}
            <Spacer size={{value: 34, unit: "px"}} />
          </Notification>
        </Container>
      </MainContainer>
    </Wrapper>

  );

}
