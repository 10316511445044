import React from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import {useSelector} from "react-redux";
import {getLocale} from "../../store/localization/selectors";
import { getLayout, getConfig } from "./../../store/settings/selectors";

import CompactMenuIcon from './../../components/App/CompactMenuIcon';

import {LAYOUT_XS, LAYOUT_SM} from './../../settings';

export const CompactMenuIconWrapper = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 5px;
  right: 15px;
   width: 46px;
   height: 46px;
   flex-grow: 0;
   flex-shrink: 0;
   margin-left: 7px;
   z-index: 9999;
`;
const Container = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 30px auto;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  height: 100vh;
  overflow-x: none;
  overflow-y: auto;
`

function VIR({ lang }) {
    return (
        lang === "it" ?
            <>
                <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
                <p><strong>Condizioni generali di contratto</strong></p>
                <p>Prendi visione delle <a href="https://vrf-app.firebaseapp.com/usage-conditions-vinitaly-it.html">condizioni generali di contratto</a> che disciplinano l'uso di Vinitaly Plus&nbsp;</p>
                <p><strong>Privacy</strong></p>
                <p>Prendi visione dei documenti privacy relativi al Vinitaly Plus:</p>
                <p>-<a href="https://vrf-app.firebaseapp.com/privacy-policy-vinitaly-it.html">informativa privacy Vinitaly Plus</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione al Vinitaly Plus e per il suo utilizzo;</p>
                <p>-<a href="https://vinitaly.com/it/verona/cookie/">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
                <p>-<Link to="terms/privacy-app">informativa privacy APP</Link> riguardante il trattamento dei dati forniti o generati usando l&rsquo;app del Vinitaly.</p>
            </>
            :
            <>
                <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identity and contact data of the service provider</strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
                <p><strong>Terms</strong><strong> &amp; </strong><strong>conditions</strong></p>
                <p>Read the <a href="https://vrf-app.firebaseapp.com/usage-conditions-vinitaly-en.html">general terms and conditions</a> that regulate the use of the Vinitaly Plus</p>
                <p><strong>Privacy</strong></p>
                <p>
                    Read the privacy documents related to the Vinitaly Plus:<br/><br/>
                    - <a href="https://vrf-app.firebaseapp.com/privacy-policy-vinitaly-en.html">privacy notice Vinitaly Plus</a> regarding the processing of personal data provided for the Vinitaly Plus subscription and use,<br/>
                    - <a href="https://vinitaly.com/en/verona/cookie">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/>
                    - <Link to="terms/privacy-app">privacy notice APP</Link> concerning the processing of data provided or generated using the Vinitaly App.
                </p>
            </>
    );
}

function MDM({ lang }) {

    return (
        lang === "it" ?
            <>
                <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
                <p><strong>Condizioni generali di contratto</strong></p>
                <p>Prendi visione delle <a rel="noopener noreferrer" target="_blank" href="https://vrf-app.firebaseapp.com/usage-conditions-marmomac-it.html">condizioni generali di contratto</a> che disciplinano l'uso di Marmomac Plus&nbsp;</p>
                <p><strong>Privacy</strong></p>
                <p>Prendi visione dei documenti privacy relativi al Marmomac Plus:</p>
                <p>-<a rel="noopener noreferrer" target="_blank" href="https://vrf-app.firebaseapp.com/privacy-policy-marmomac-it.html">informativa privacy Marmomac Plus</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Marmomac Plus e per il suo utilizzo;</p>
                <p>-<a rel="noopener noreferrer" target="_blank" href="https://www.marmomac.com/privacy-e-cookie-policy/">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
                <p>-<Link to="terms/privacy-app">informativa privacy APP</Link> riguardante il trattamento dei dati forniti o generati usando l&rsquo;App Marmomac.</p>
            </>
            :
            <>
                <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identity and contact data of the service provider</strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>
                <p><strong>Terms</strong><strong> &amp; </strong><strong>conditions</strong></p>
                <p>Read the <a href="https://vrf-app.firebaseapp.com/usage-conditions-marmomac-en.html">general terms and conditions</a> that regulate the use of the Marmomac Plus</p>
                <p><strong>Privacy</strong></p>
                <p>
                    Read the privacy documents related to the Marmomac Plus:<br/><br/>
                    - <a href="https://vrf-app.firebaseapp.com/privacy-policy-marmomac-en.html">privacy notice Marmomac Plus</a> regarding the processing of personal data provided for the Marmomac Plus subscription and use,<br/>
                    - <a href="https://www.marmomac.com/en/privacy-and-cookie-policy/">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/>
                    - <Link to="terms/privacy-app">privacy notice APP</Link> concerning the processing of data provided or generated using the App.
                </p>
            </>
    );

}

function AGR({ lang }) {
    return (
    lang === "it" ?
        <>
            <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

            <p><strong>Privacy</strong></p>
            <p>Prendi visione dei documenti privacy relativi a Fieragricola Plus:</p>
            <p>-<a rel="noopener noreferrer" target="_blank" href="https://catalogo.fieragricola.it/it/contact-policy">informativa privacy</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Fieragricola Plus e per il suo utilizzo;</p>
            <p>-<a rel="noopener noreferrer" target="_blank" href="https://www.fieragricola.it/it/privacy-e-cookie-policy">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
        </>
        :
        <>
            <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
            <p><strong>Identity and contact data of the service provider</strong></p>
            <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

            <p><strong>Privacy</strong></p>
            <p>Read the privacy documents related to the Fieragricola Plus:<br/><br/>- <a href="https://catalogo.fieragricola.it/en/contact-policy">privacy notice Fieragricola Plus</a> regarding the processing of personal data provided for the Fieragricola Plus subscription and use,<br/>- <a href="https://www.fieragricola.it/en/privacy-and-cookie-policy">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/></p>
        </>
    );
}

function FIC({ lang }) {
    return (lang === "it" ?
            <>
                <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

                <p><strong>Privacy</strong></p>
                <p>Prendi visione dei documenti privacy relativi a Fieracavalli Plus:</p>
                <p>-<a href="contact-policy">informativa privacy</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Fieracavalli Plus e per il suo utilizzo;</p>
                <p>-<a rel="noopener noreferrer" target="_blank" href="https://fieracavalli.it/it/privacy-policy-siti-internet/">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
            </>
            :
            <>
                <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identity and contact data of the service provider</strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

                <p><strong>Privacy</strong></p>
                <p>Read the privacy documents related to the Fieracavalli Plus:<br/><br/>- <a href="contact-policy">privacy notice Fieracavalli Plus</a> regarding the processing of personal data provided for the Fieracavalli Plus subscription and use,<br/>- <a href="https://fieracavalli.it/en/privacy-policy-websites/">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/></p>
            </>
    );
}

function SAM({ lang }) {
    return (
        lang === "it" ?
            <>
                <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

                <p><strong>Privacy</strong></p>
                <p>Prendi visione dei documenti privacy relativi a Samoter Plus:</p>
                <p>-<a href="contact-policy">informativa privacy</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Samoter Plus e per il suo utilizzo;</p>
                <p>-<a rel="noopener noreferrer" target="_blank" href="https://www.samoter.it/it/privacy-e-cookie-policy">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
            </>
            :
            <>
                <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identity and contact data of the service provider</strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

                <p><strong>Privacy</strong></p>
                <p>Read the privacy documents related to the Samoter Plus:<br/><br/>- <a href="contact-policy">privacy notice Samoter Plus</a> regarding the processing of personal data provided for the Samoter Plus subscription and use,<br/>- <a href="https://www.samoter.it/en/privacy-and-cookie-policy">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,<br/></p>
            </>
    );
}

function ASF({ lang }) {
    return (lang === "it" ?
            <>
                <p><strong>NOTE LEGALI - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identit&agrave; e dati di contatto del titolare del sito web </strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, caiptale sociale 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

                <p><strong>Privacy</strong></p>
                <p>Prendi visione dei documenti privacy relativi a Asphaltica Plus:</p>
                <p>-<a href="contact-policy">informativa privacy</a> riguardante il trattamento dei dati personali forniti per l&rsquo;iscrizione a Asphaltica Plus e per il suo utilizzo;</p>
                <p>-<a rel="noopener noreferrer" target="_blank" href="https://www.asphaltica.it/it/privacy-e-cookie-policy">privacy cookie policy</a> riguardante il trattamento dei dati di navigazione e delle informazioni anche tramite cookie ed altri strumenti di tracciamento;</p>
            </>
            :
            <>
                <p><strong>LEGAL NOTICE - T&amp;C - PRIVACY</strong></p>
                <p><strong>Identity and contact data of the service provider</strong></p>
                <p>Veronafiere S.p.a.<br/>C.F. e P. IVA 00233750231, n. REA VR &ndash; 74722, share capital 90.912.707,00 Euro<br/>V.le del Lavoro 8, 37135 &ndash; Verona<br/>tel. 045 829 8111, indirizzo e-mail <a href="mailto:info@veronafiere.it">info@veronafiere.it</a>, indirizzo PEC <a href="mailto:mail@pec.veronafiere.it">mail@pec.veronafiere.it</a></p>

                <p><strong>Privacy</strong></p>
                <p>Read the privacy documents related to the Asphaltica Plus:</p>
                <p>- <a href="contact-policy">privacy notice Asphaltica Plus</a> regarding the processing of personal data provided for the Asphaltica Plus subscription and use,</p>
                <p>- <a href="https://www.asphaltica.it/en/privacy-and-cookie-policy">privacy cookie policy</a> regarding the processing of browsing data and information also through cookie and other tracking tools,</p>
            </>
    );
}

function PrivacyPolicy({ lang }) {

    return (lang === "it" ?
        <>
            <div class="container py-4"><h1 class="h3">INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI AI SENSI DELL’ART. 13 DEL
                REGOLAMENTO (UE) 2016/679 (“GDPR”) - APP</h1>
                <p>Con riguardo all’installazione ed all’utilizzo dell’applicazione per smartphone relativa alla Manifestazione ed alla relativa piattaforma (di seguito, “<strong>Piattaforma</strong>”), Le forniamo le informazioni richieste dal GDPR circa il trattamento dei dati personali forniti o generati utilizzando l’app.</p>
                <ul class="mb-5">
                    <li><h3 class="h5">1. Identità e dati di contatto del titolare del trattamento</h3></li>
                    <p>Titolare del trattamento è Veronafiere S.p.A., P. IVA 00233750231, Viale del Lavoro, 8, 37135 - Verona (VR), tel. 045 8298111, e-mail info@veronafiere.it, PEC mail@pec.veronafiere.it (di seguito, anche “<strong>Veronafiere</strong>” o “<strong>Titolare</strong>”).</p>
                    <li><h3 class="h5">2. DATI DI CONTATTO DEL RESPONSABILE DELLA PROTEZIONE DATI (DPO)</h3></li>
                    <p>Il DPO è contattabile all’indirizzo e-mail: dpo@veronafiere.eu</p>
                    <li class="mb-4"><h3 class="h5">3. DATI TRATTATI, FINALITÀ DEL TRATTAMENTO, BASI GIURIDICHE E PERIODO DI CONSERVAZIONE DEI DATI</h3>
                        <ol>
                            <li>
                                <p><strong>Dati di utilizzo dell’app</strong>: si tratta dei dati generati automaticamente dal dispositivo o dall’app.</p>
                                <p>In particolare, i sistemi informatici e le procedure software preposte al funzionamento dell’app acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che, per loro stessa natura, potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano: il sistema operativo, il modello e la marca del dispositivo e i dati relativi alla connettività di rete.</p>
                                <p>Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso dell’app e per controllarne il corretto funzionamento, e vengono cancellati immediatamente dopo l’elaborazione, salvo eventuali accertamenti di responsabilità in caso di reati informatici.</p>
                                <p><u>Finalità</u>: i dati sono trattati per la gestione tecnica dell’app e quindi per permetterLe di usufruire delle funzionalità dell’app.</p>
                                <p><u>Base giuridica</u>: l’esecuzione di un contratto di cui Lei è parte.</p>
                                <p><u>Periodo di conservazione dei dati</u>: i dati saranno conservati per tutto il tempo di utilizzo dell’app e cancellati trascorsi 24 mesi di inattività.</p>
                            </li>
                            <li>
                                <p><strong>Dati trattati in fase di installazione / utilizzo dell’app</strong>: Veronafiere, previa autorizzazione, può memorizzare o accedere ad informazioni archiviate sul Suo device (es. foto, contatti, ecc.). Resta inteso che tali autorizzazioni possono essere gestite in qualsiasi momento accedendo all’apposita sezione dei permessi delle app all’interno delle impostazioni del Suo dispositivo.</p>
                                <p><u>Finalità</u>: i dati sono trattati per permetterLe di utilizzare le specifiche funzionalità previste dall’app (ad es. scattare fotografie durante l’evento).</p>
                                <p><u>Base giuridica</u>: il Suo consenso.</p>
                                <p><u>Periodo di conservazione dei dati</u>: i dati saranno conservati per tutto il tempo di utilizzo dell’app e cancellati trascorsi 24 mesi di inattività.</p>
                            </li>
                            <li>
                                <p><strong>Dati di geolocalizzazione</strong>: nel caso in cui Lei abbia attivato, al momento dell’installazione dell’app o anche successivamente, i servizi di geolocalizzazione tramite GPS e/o Bluetooth LTE, Veronafiere, quando l’app è attiva, acquisirà dati relativi alla Sua posizione geografica.</p>
                                <p>La raccolta dei dati è funzionale a fornirLe informazioni / notifiche push sul posizionamento degli espositori e dei loro stand o sulla manifestazione in corso, previa la rilevazione del dispositivo in prossimità di determinate aree della manifestazione fieristica.</p>
                                <p>I dati di geolocalizzazione non sono archiviati nell’app.</p>
                                <p>I servizi di geolocalizzazione potranno essere disattivati in qualsiasi momento accedendo all’apposita sezione dei permessi del sistema operativo del Suo dispositivo (Android o IOS). Da quel momento l’app non sarà più in grado di raccogliere dati di geolocalizzazione. Allo stesso modo disattivando i servizi bluetooth tramite accesso all’apposita sezione dei permessi del sistema operativo del Suo dispositivo, l’app non sarà più in grado di raccogliere dati sulla posizione tramite il bluetooth. Anche le notifiche push possono essere disattivate nelle modalità di cui sopra.</p>
                                <p>La mancata attivazione dei servizi di geolocalizzazione, dei servizi bluetooth e delle notifiche push non preclude la fruibilità delle altre funzioni dell’app.</p>
                                <p><u>Finalità</u>: i dati sono trattati per permetterLe di utilizzare le specifiche funzionalità previste dall’app, come meglio spiegato sopra.</p>
                                <p><u>Base giuridica</u>: il Suo consenso.</p>
                                <p><u>Periodo di conservazione dei dati</u>: i dati saranno conservati per il tempo strettamente necessario a fornirLe il servizio di localizzazione e automaticamente e immediatamente cancellati. I dati di geolocalizzazione acquisiti tramite Bluetooth sono anonimi e, in ogni caso, decorso un termine di conservazione di 30 giorni, saranno cancellat</p>
                            </li>
                            <li>
                                <p><strong>Dati da Lei forniti</strong>: per poter usufruire dei servizi descritti nelle condizioni contrattuali della Piattaforma, fruibili tramite app (di seguito “Servizi”), dovrà previamente iscriversi sul sito web relativooppure, se è già iscritto, accedere con le Sue credenziali. Per le informazioni sul trattamento dei dati personali relativamente all’iscrizione sulla Piattaforma ed all’uso dei Servizi, si prega di visionare la relativa informativa.</p>
                                <p>Uno dei Servizi è quello che consente di metterLa in contatto con l’espositore di Suo interesse (scambio di “business card”), comportando, quindi, la comunicazione dei suoi dati a tale soggetto.</p>
                                <p><u>Finalità</u>: i dati sono trattati per permetterLe di usufruire dei Servizi.</p>
                                <p><u>Base giuridica</u>: l’esecuzione di un contratto di cui Lei è parte.</p>
                                <p><u>Periodo di conservazione dei dati</u>: i dati saranno conservati, come indicato nell’informativa sull’iscrizione alla Piattaforma menzionata sopra, per tutta la durata contrattuale e, dopo la cessazione, per 10 anni.</p>
                                <p>Decorsi i termini di conservazione sopra riportati, i dati saranno distrutti o resi anonimi, compatibilmente con le tempistiche tecniche di cancellazione e backup.</p>
                            </li>
                        </ol>
                    </li>
                    <li><h3 class="h5">4. Destinatari dei dati</h3></li>
                    <p>In caso di scambio della business card, i Suoi dati saranno visibili all’espositore prescelto.</p>
                    <p>I Suoi dati potranno essere comunicati a soggetti terzi operanti in qualità di titolari autonomi, quali autorità pubbliche e studi professionali.</p>
                    <p>I Suoi dati potranno essere altresì trattati da altri soggetti, nominate responsabili del trattamento ai sensi dell’art. 28 del GDPR, che svolgono per conto del titolare compiti di natura tecnica funzionali al perseguimento delle finalità specificate sopra, come sviluppo, gestione e manutenzione dell’app.</p>
                    <p>Inoltre, i dati sono trattati dai dipendenti di Veronafiere - appartenenti alle funzioni aziendali deputate al perseguimento delle finalità sopraindicate - che sono stati espressamente autorizzati al trattamento e che hanno ricevuto adeguate istruzioni operative.</p>

                    <li><h3 class="h5">5. Diritti degli interessati – reclamo all’Autorità di controllo</h3></li>
                    <p>Gli interessati possono chiedere al Titolare la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano e, in tal caso, l’accesso ai dati e alle informazioni di cui all’art. 15, la cancellazione dei dati nei casi previsti dall’17, la rettifica dei dati inesatti, l’integrazione dei dati incompleti, la limitazione del trattamento nei casi previsti dall’art. 18 GDPR, nonché l’opposizione al trattamento, per motivi connessi alla loro situazione particolare, nelle ipotesi di legittimo interesse del Titolare.</p>
                    <p>Gli interessati, inoltre, nel caso in cui il trattamento sia basato sul consenso o sul contratto e sia effettuato con strumenti automatizzati hanno il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i dati, nonché, se tecnicamente fattibile, di trasmetterli ad altro titolare senza impedimenti (“diritto alla portabilità dei dati”).</p>
                    <p>Il consenso prestato può essere revocato in ogni momento accedendo all’apposita sezione delle autorizzazioni nelle impostazioni del Suo device.</p>
                    <p>Per l'esercizio dei diritti l’interessato può rivolgersi a Veronafiere ai punti di contatto di cui al par. 1 oppure scrivendo un’e‐mail a privacy@veronafiere.it.</p>
                    <p>Gli interessati hanno il diritto di proporre reclamo all'Autorità di controllo competente nello Stato membro in cui risiedono abitualmente o lavorano o dello Stato in cui si è verificata la presunta violazione.</p>

                </ul>
                <small>Ultimo aggiornamento: ottobre 2022</small></div>
        </>
        :
        <>
            <div class="container py-4"><h1 class="h3">PRIVACY NOTICE PURSUANT TO ART. 13 OF THE REGULATION (EU) 2016/679 (“GDPR”) - APP</h1>
                <p>With regards to the installation and use of the app for smartphones concerning the Exhibition and the related digital platform (hereinafter referred to as "<strong>Platform</strong>"), Veronafiere S.p.A. provides you with the following information on the processing of personal data provided or generated using such app. </p>
                <ul class="mb-5">
                    <li><h3 class="h5">1. IDENTITY AND CONTACT DETAILS OF THE DATA CONTROLLER</h3></li>
                    <p>The data controller is Veronafiere S.p.A., VAT 00233750231, Viale del Lavoro, 8, 37135 - Verona (VR), tel. 045 8298111, email info@veronafiere.it, PEC mail@pec.veronafiere.it  (hereinafter referred to as “<strong>Veronafiere</strong>” or “<strong>Controller</strong>”)</p>
                    <li><h3 class="h5">2. CONTACT DETAILS OF THE DATA PROTECTION OFFICER (DPO)</h3></li>
                    <p>You can contact the DPO at the email address dpo@veronafiere.eu</p>
                    <li class="mb-4"><h3 class="h5">3. CATEGORIES OF DATA PROCESSED, DATA PROCESSING PURPOSES AND LEGAL BASIS, DATA RETENTION</h3>
                        <ol>
                            <li>
                                <p><strong>Data on app’s use</strong>: which are automatically generated by the device or by the app.</p>
                                <p>The IT system and software aimed at the correct functioning of the app allow the acquisition of information whose transmission is automatic as per the Internet communication protocol. These data are not collected for the purpose of being associated to specific data subjects even if their nature – combined with other data collected by third parties – could potentially lead to the user’s identification. This is the case of information concerning operating system, device characteristics, internet connectivity, etc. The mentioned data are collected with the only purpose of extracting anonymous statistics about the app’s use and operation and are destroyed immediately after processing, without prejudice to the detection of responsibility in case of cybercrimes.</p>
                                <p><u>Purposes</u>: data are processed for the technical operation of the app and thus to allow you to use its functions.</p>
                                <p><u>Legal basis</u>: the performance of a contract to which you are party.</p>
                                <p><u>Data retention</u>: data will be stored for as long as the app is used and deleted after 24 months of inactivity.</p>
                            </li>
                            <li>
                                <p><strong>Data processed during the installation / use of the app</strong>: Veronafiere, with prior permission, may store or access information stored on your device (e.g. photos, contacts, etc.). It is understood that these permissions can be managed at any time by accessing the app permissions section within the settings of your device.</p>
                                <p><u>Purposes</u>: data are processed to allow you to use the specific functions provided by the app (e.g., take photographs during the event).</p>
                                <p><u>Legal basis</u>: your consent</p>
                                <p><u>Data retention</u>: data will be stored for as long as the app is used and deleted after 24 months of inactivity.</p>
                            </li>
                            <li>
                                <p><strong>Geolocation data</strong>: if you have activated the geolocation services via GPS and/or Bluetooth LTE when installing the app or later, the app will obtain data about your location when it is active.</p>
                                <p>The data collection is designed to provide you with information/ send push notifications on the positioning of exhibitors and their stands, after detecting the device's access to certain areas of the fair exhibition.</p>
                                <p>Geolocation data is not stored in the app.</p>
                                <p>Geolocation services can be turned off at any time by accessing the location permissions section of your device's operating system (Android or IOS). From that moment on, the app will no longer be able to collect geolocation data. In the same way if you turn off the Bluetooth services by accessing the permissions section of your device settings, the app will no longer be able to collect location data via Bluetooth. Push notifications can also be turned off in the ways indicated above.</p>
                                <p>The lack of activation of geolocation services, Bluetooth services and push notifications does not preclude the usability of other functions of the app.</p>
                                <p><u>Purposes</u>: data are processed to allow you to use the specific functions provided by the app, as explained above.</p>
                                <p><u>Legal basis</u>: Your consent.</p>
                                <p><u>Data retention</u>: data will be stored for the time strictly necessary to provide you with the geolocation service and, after that, immediately and automatically deleted on the device. The geolocation data collected via Bluetooth are anonymous and, in any case, after a storage period of 30 days, will be deleted.</p>
                            </li>
                            <li>
                                <p><strong>Data provided directly by you</strong>: in order to use the services described in the Platform terms and conditions, available through the app (hereinafter referred to as "Services"), you have first to subscribe on the website or, if you are already subscribed, log in with your credentials. For information on the processing of personal data regarding such subscription and the use of the Services, please see the relevant privacy notice.</p>
                                <p>One of the Services is the one that allows us to put you in contact with the exhibitor you are interested in (exchange of "business card"), thus involving the communication of your data to such subject.</p>
                                <p><u>Purposes</u>: data are processed to allow you to use the Services.</p>
                                <p><u>Legal basis</u>: the performance of a contract to which you are party.</p>
                                <p><u>Data retention</u>: data, as pointed out in the Platform privacy notice mentioned above, will be stored for the entire duration of the contract and for the following 10 years.</p>
                                <p>Once the above data retention terms have expired, data will be destroyed or anonymized, compatibly with the technical timing for erasure and backup.</p>
                            </li>
                        </ol>
                    </li>
                    <li><h3 class="h5">4. CATEGORIES OF RECEPIENTS TO WHOM DATA MAY BE DISCLOSED</h3></li>
                    <p>If you exchange your business card, your data will be visible to the chosen exhibitor.</p>
                    <p>Data may be disclosed to parties acting as controllers, such as public authorities and professional firms.</p>
                    <p>Data may be processed, on behalf of the Controller, by third parties, appointed as Data Processors pursuant to art. 28 of GDPR, carrying out technical activities that are functional to the pursuit of the purposes indicated above, such as app development, management and maintenance.</p>
                    <p>Furthermore, data are processed by Veronafiere employees who have been expressly authorised to process such data for the above-mentioned purposes and have received adequate operating instructions.</p>

                    <li><h3 class="h5">5. DATA SUBJECTS’ RIGHTS</h3></li>
                    <p>
                        Data subject shall have the following rights:<br />
                        - to access data concerning him/her in accordance with Article 15 GDPR,<br />
                        - to obtain the rectification of inaccurate data,<br />
                        - to have incomplete data completed,<br />
                        - to obtain the erasure of data in the cases provided for by Article 17 GDPR,<br />
                        - to obtain restriction of processing in the cases provided for by Article 18 GDPR,<br />
                        - to object at any time, on grounds relating to his/her own particular situation, to the processing carried out in the legitimate interest of the Controller,<br />
                        - where the processing is based on consent or contract and is carried out by automated means, to receive data in a structured, commonly used and machine-readable format and to transmit those data to another controller without hindrance from the controller to which the personal data have been provided (the right to data portability).
                    </p>
                    <p>In particular, consent can be withdrawn at any time by accessing the permissions section in the device settings.</p>
                    <p>In order to exercise your rights, you can contact the Controller at the contact points specified at par. 1 by e-mail to privacy@veronafiere.it.</p>
                    <p>Data subjects shall have the right to lodge a complaint with the competent supervisory authority in the Member State of his or her habitual residence, place of work or place of the alleged infringement.</p>
                </ul>
                <small>Last update: October 2022</small></div>
        </>);

}

function Terms({page = "main"}) {

//   const translations = useSelector(getTranslations);
  const lang = useSelector(getLocale);
  const layout = useSelector(getLayout);
  const config = useSelector(getConfig);
// console.log(translations['terms']);
// console.log(translations['terms']['legal_area']);
  return (
    <Container>
      {layout === LAYOUT_XS || layout === LAYOUT_SM ?
        <CompactMenuIconWrapper>
          <CompactMenuIcon />
        </CompactMenuIconWrapper>
      : null }
        {page === 'main' ? (
            <>
                {config.tenant === "VIR" && <VIR lang={lang} />}
                {config.tenant === "MDM" && <MDM lang={lang} />}
                {config.tenant === "AGR" && <AGR lang={lang} />}
                {config.tenant === "FIC" && <FIC lang={lang} />}
                {config.tenant === "SAM" && <SAM lang={lang} />}
                {config.tenant === "ASF" && <ASF lang={lang} />}
            </>
        ) : null}
        {page === 'privacy-app' ?
          <>
            {config.tenant === "VIR" || config.tenant === "MDM" ? <PrivacyPolicy lang={lang} /> : null }
          </>
        : null}
    </Container>
  );

}

export default Terms;
